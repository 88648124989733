<template>
  <el-container>
    <!-- 头部 -->
    <el-header>
      <div class="logo">
        <div class="header_logo">
          <img src="../assets/images/logo.png" alt />
          <!-- <span
            style="font-size:18px;color: black; font-weight: bold; margin-left: 6px;"
          >
            中亮新能源-户用</span
          > -->
        </div>
        <div class="flex firstM ml20">
          <div
            :class="currentIndex == index ? 'fist-menu ' : 'fist-menu'"
            v-for="(item, index) in firstMenuList"
            :key="item.menuCode"
            @click="getMenuList(item.childrens, index, item.name)"
          >
            <div
              style="font-size: 18px"
              :class="currentIndex == index ? 'active ' : ''"
              @click="changeMenu"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- <div class="toggle-button ml20" @click="togleCollapse">
          <i class="el-icon-s-fold f20" ref="icon"></i>
        </div> -->
      </div>

      <div class="user flex align-center">
        <div class="userInfo">
          <span class="vm">{{ name }}</span>
          <span class="vm">[{{ loginName }}]</span>
          <!-- <img :src="imageUrl" class="vm round ml10" alt width="32" height="32"
            onerror="this.src='https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2100401123,2895311668&fm=26&gp=0.jpg'" /> -->
        </div>

        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-more"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="setting">账户设置</el-dropdown-item>
            <el-dropdown-item command="logout">登出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!-- 主体 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside v-if="showSidebar" :width="isCollapse ? '64px' : '200px'">
        <el-menu
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          :default-active="activeIndex"
          background-color="#fff"
          text-color="#000"
          active-text-color="#ffffff"
        >
          <!-- 一级菜单  -->
          <!-- <el-menu-item :index="item.link" @click="stairSaveNavState( '/' +item.link, item)"
            v-if="item.children || item.children.length == 0">
            <el-image :src="item.iconUrl" style="width:15px;height:15px;margin-right:10px"></el-image>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item> -->

          <!-- 有二级的菜单 -->
          <!-- <el-submenu
            v-if="item.children && item.children.length > 0"
            :index="item.link"
          > -->
          <!-- <template slot="title">
              <el-image
                :src="item.iconUrl"
                style="width:15px;height:15px;margin-right:10px"
              ></el-image>
              <span>{{ item.name }}</span>
            </template> -->
          <!-- <el-menu-item
              :index="i.link"
              @click="stairSaveNavState('/' + i.link, i)"
              v-for="i in item.children"
              :key="i.menuCode"
            >
              <template slot="title">
                <el-image
                  :src="i.iconUrl"
                  style="width:20px;height:20px;margin-right:10px"
                ></el-image>
                <span>{{ i.name }}</span>
              </template>
            </el-menu-item> -->

          <!-- 一级菜单  -->
          <el-menu-item
            v-for="(item, index) in menuList"
            :key="item.menuCode"
            :index="item.menuCode"
            @click="handleFirst(item, index)"
          >
            <img
              v-if="item.iconUrl"
              :src="item.iconUrl"
              class="avatar"
              style="width: 17px; height: 17px; display: inline-block"
            />
            <i v-else class="iconfont icon-baobiao"></i>
            <span style="margin-left: 10px">{{ item.name }}</span>
          </el-menu-item>
          <!-- </el-submenu> -->
        </el-menu>
      </el-aside>

      <!-- 二三级菜单 -->
      <div v-if="menuVisible" class="win" @click="closeLeftMenu">
        <div
          class="floatMenu flex flex-column"
          :style="{ top: top + 'px' }"
          @click.stop="() => {}"
        >
          <div ref="right_menu">
            <div class="right-menu fistNavName">{{ fistNavName }}</div>
            <div
              class="right-menu flex"
              v-for="(item, index) in secondList"
              :key="item.name"
              @mouseenter="mouseSenter(index)"
            >
              <div class="second">
                <div
                  :class="
                    index == hoverActive
                      ? ['second-active', 'second-item']
                      : ['second-item']
                  "
                  style="color: #999"
                >
                  {{ item.name }}
                </div>
              </div>

              <div class="third">
                <div class="flex flex-wrap">
                  <div
                    class="third-item pointer"
                    v-for="item1 in item.childrens"
                    :key="item1.menuCode"
                    @click="stairSaveNavState('/' + item1.link, item1)"
                  >
                    {{ item1.name }}
                  </div>
                  <!-- <div class="thirdLine"></div> -->
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="flex" style="height:100%">
            <div class="second" />
            <div class="third" />
          </div> -->
        </div>
      </div>

      <!-- 内容主体 -->
      <el-main class="main-center">
        <div class="template-tabs" style="margin-left: 10px">
          <el-tabs
            v-model="activeTab"
            type="card"
            closable
            @tab-click="tabClick"
            @tab-remove="removeTab"
          >
            <el-tab-pane
              :key="item.name"
              v-for="item in tabsItem"
              :label="item.title"
              :name="item.name"
            >
            </el-tab-pane>
          </el-tabs>
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>

    <div id="loader-wrapper" v-show="showLoading">
      <div id="loader"></div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
      <div class="load_title">
        正在载入,请耐心等待..
        <br />
        <!-- <span>V1.0</span> -->
      </div>
    </div>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      firstIndex: 0,
      currFirstItem: {},
      // 左侧菜单数据
      menuList: [],
      tabsItem: [],
      secondList: [],
      thirdList: [],
      iconObj: {
        1: "el-icon-s-home",
        2: "iconfont icon-user",
        3: "iconfont icon-tijikongjian",
        4: "iconfont icon-shangpin",
        5: "iconfont icon-danju",
        6: "iconfont icon-baobiao",
        7: "iconfont icon-baobiao",
        8: "iconfont icon-danju"
      },
      // 默认不折叠
      isCollapse: false,
      // 被激活导航地址
      activePath: "/index",
      loginName: "",
      showLoading: true,
      imageUrl: "",
      activeTab: "1", //默认显示的tab
      tabIndex: 1, //tab目前显示数
      firstMenuList: [],
      currentIndex: 0,
      //  菜单
      menuVisible: false,
      activeIndex: "0",
      top: 60,
      menuTop: 0,
      menuLeft: 0,
      hoverActive: "",
      fistNavName: "",
      showSidebar: false,
      menuindex:''
    };
  },
  created() {
    if (window.location.hash == "#/Welcome") {
      this.showSidebar = false;
    } else {
      this.showSidebar = true;
    }
        
    this.name = JSON.parse(localStorage.getItem("userInfo"))?.userInfo.name;
    this.activePath = sessionStorage.getItem("activePath");
    this.loginName = localStorage.getItem("loginName");
    this.activeIndex = localStorage.getItem("activeIndex");
    this.activeTab = localStorage.getItem("activeTab");
    //  this.getMenuList();
    // this.getUserInfo();

    this.getFirstMenu();
    // this.clearRouter()
  },
  computed: {
    setHeight() {
      return document.documentElement.clientHeight - 65;
    },
    activeNav() {
      //当前激活的导航
      return this.$route.path;
    }
  },
  mounted() {
    // this.getMenuList();
    let firstM = document.querySelector(".firstM");
    firstM.addEventListener("wheel", function(e) {
      let dundong = -e.wheelDelta;
      firstM.scrollLeft = firstM.scrollLeft + dundong;
    });
  },
  watch: {
    '$route'(to, from) {
      // if(from.path == '/commerpro' ||from.path == '/accep' ||from.path == '/conso'){
      //   console.log('00');
      // } else {
      //   if(window.location.hash == '#/prospecying') {
      //    this.showSidebar = true
      //    let currIndex = ''
      //    this.firstMenuList.map((item,index) => {if(item.name == '审核'){
      //     currIndex = index
      //    }})
      //    let arr = this.firstMenuList.filter(item => {return item.name == '审核'})
      //    this.getMenuList(arr[0].childrens, currIndex,arr[0].name)
      //    this.handleFirst(arr[0].childrens[0],0)
      //  }
      // }
      if(from.path == '/Welcome') {
        console.log(this.menuindex,'hhhhhhh');
         if(this.menuindex == 0){
          this.showSidebar = true
          let currIndex = ''
          this.firstMenuList.map((item,index) => {if(item.name == '审核'){
           currIndex = index
          }})
          let arr = this.firstMenuList.filter(item => {return item.name == '审核'})
          this.getMenuList(arr[0].childrens, currIndex,arr[0].name)
          this.handleFirst(arr[0].childrens[0],0)
         }
      }
      
      // 当路由改变时执行
      if (window.location.hash == '#/Welcome') {
        this.showSidebar = false
      } else {
        this.showSidebar = true
      }
    }
  },
  methods: {
    changeMenu() {
      this.showSidebar = true;
    },
    // 菜单开始
    handleFirst(item, index) {

      console.log(item,index,304);
      //一级菜单
      this.fistNavName = item.name;
      this.menuVisible = false;
      // this.activeIndex = index.toString();
      this.activeIndex = item.menuCode;
      localStorage.setItem("activeIndex", item.menuCode);
      let rightMenuHeight = 0;
      this.secondList = item.childrens;
      

      if (this.secondList.length > 0) {
        this.menuVisible = true;
        this.$nextTick(() => {
          rightMenuHeight = this.$refs.right_menu.offsetHeight;
          if (rightMenuHeight + index * 56 < window.innerHeight - 60) {
            this.top = 60 + index * 56;
          } else {
            this.top = window.innerHeight - rightMenuHeight - 60;
          }
          // if (index == 1) {
          //   this.top = 0
          // }
        });
      }
    },

    mouseSenter(index) {
      this.hoverActive = index;
    },

    // 菜单的折叠与展开
    togleCollapse() {
      if (this.$refs.icon.className == "el-icon-s-fold f20") {
        this.$refs.icon.className = "el-icon-s-unfold f20";
      } else {
        this.$refs.icon.className = "el-icon-s-fold f20";
      }
      this.isCollapse = !this.isCollapse;
    },
    // 保存连接的激活地址
    stairSaveNavState(activePath, menu) {
      
      this.menuVisible = false;
      localStorage.setItem("activePath", activePath);
      // if (menu.name === "首页") {
      //   this.tabsItem = [];
      //   return false;
      // } else {
      //   //主窗口添加显示
      //   this.addTab(menu);
      // }
      
       this.addTab(menu);
    },
    // 保存连接的激活地址
    saveNavState(activePath) {
      localStorage.setItem("activePath", activePath);
    },
    closeMenu() {
      //关闭右击菜单
      this.tabMenuVisable = false;
    },

    closeLeftMenu() {
      console.log('999');
      //关闭右击菜单
      this.menuVisible = false;
    },

    //清空路由
    clearRouter(){
      if(this.$route.path == "/Welcome"){
        this.tabsItem = []
      }
    },

    getFirstMenu() {
      var that = this;
      that.$http
        .post("/menuInfo/getUserMenu", {
          loginName: JSON.parse(localStorage.getItem("userInfo")).userInfo
            .loginName,
          isAdmin: 1
        })
        .then(function(res) {
          if (res.data.code == 200) {
            localStorage.setItem("menu", JSON.stringify(res.data.data));
            that.firstMenuList = res.data.data;
            that.getMenuList(res.data.data[0]?.childrens, 0, "");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    choiceFirstMu(item, i) {
      this.firstIndex = i;
      this.currFirstItem = item;
    },

    choiceFirstMu(item, i) {
      this.firstIndex = i;
      this.currFirstItem = item;
      this.isCollapse = false;
    },

    addTab(menu) {
      //判断是否已经存在了
      let menuExsit = false;
      
      for (var item of this.tabsItem) {
        
        if (menu.menuCode == item.menuCode) {
          menuExsit = true;
          this.activeTab = item.name;
          this.$router.push({ path: menu.link });
          
          
        }
      }

      if (!menuExsit) {
        //不存在
        let newTabName = menu.name;
        this.tabsItem.push({
          title: menu.name,
          name: newTabName,
          link: menu.link,
          menuCode: menu.menuCode
        });
        this.activeTab = newTabName;
        this.$router.push({ path: menu.link });
       
      }
    },
    removeTab(targetName) {
      let tabs = this.tabsItem;
      let activeName = this.activeTab;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.activeTab = activeName;
      if (this.tabsItem.length > 1) {
        this.tabsItem = tabs.filter(tab => tab.name !== targetName);

        const path = this.tabsItem[this.tabsItem.length - 1].link;
        this.$router.push({ path: path });
      }
    },
    tabClick(tab) {
      
      let path = "";
      let menuName = tab.$options.propsData.name;
      for (var item of this.tabsItem) {
        if (menuName == item.name) {
          path = item.link;
        }
      }
      this.$router.push({ path: path });
    },
    // 获取请求菜单
    async getMenuList(children, index, name) {
      this.menuindex = index
      console.log(index,'index');
      this.menuVisible = false;
      this.currentIndex = index;

      if (name == "首页") {
        this.$router.push({ path: "/Welcome" });
        this.showSidebar = false;
        this.tabsItem = []
        return;
      }
      var that = this;
      var loginName = localStorage.getItem("loginName");
      // console.log('index loginName',loginName)
      //存储超时，跳转到登陆页
      if (loginName == null || loginName == "") {
        //用户名不存在跳转登陆
        that.$router.push({ path: "/login" });
        return;
      }

      that.menuList = children;
      console.log('菜单',that.menuList)
      that.currFirstItem = that.menuList?.length ? that.menuList[0] : {};
      that.showLoading = false;
    },

    getLocalStorage(key) {
      // 取出对象
      let item = localStorage.getItem(key);
      // 先将拿到的试着进行json转为对象的形式
      try {
        item = JSON.parse(item);
      } catch (error) {
        // eslint-disable-next-line no-self-assign
        item = item;
      }
      // 如果有startTime的值，说明设置了失效时间
      if (item && item.startTime) {
        let date = new Date().getTime();
        // 如果大于就是过期了，如果小于或等于就还没过期
        if (date - item.startTime > item.expires) {
          localStorage.removeItem(name);
          return "";
        } else {
          return item.value;
        }
      } else {
        return "";
      }
    },
    // 菜单的折叠与展开
    // togleCollapse() {
    //   // console.log(this.$refs)
    //   if (this.$refs.icon.className == "el-icon-s-fold f20") {
    //     this.$refs.icon.className = "el-icon-s-unfold f20";
    //   } else {
    //     this.$refs.icon.className = "el-icon-s-fold f20";
    //   }
    //   this.isCollapse = !this.isCollapse;
    // },
    // // 保存连接的激活地址
    // stairSaveNavState(activePath, menu) {
    //   window.sessionStorage.setItem("activePath", activePath);
    //   //主窗口添加显示
    //   this.addTab(menu);
    // },
    // // 保存连接的激活地址
    // saveNavState(activePath) {
    //   window.sessionStorage.setItem("activePath", activePath);
    // },

    // 监听下拉菜单子选项点击事件
    handleCommand(command) {
      //this.$message("click on item " + command);
      if (command == "logout") {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("loginName");
        this.$router.push("/login");
      } else {
        this.$router.push("/myAccountView");
      }
    },

    //获取个人信息
    // getUserInfo () {
    //   var that = this;
    //   that.$http
    //     .post("/userInfo/queryOne", { loginName: that.loginName })
    //     .then(function (response) {
    //       console.log(response.data.data);
    //       if (response.data.code == 200) {
    //         that.imageUrl = response.data.data.logo;
    //       }
    //     });
    // },
    //进入消息详情
    goNewsPage() {
      this.$router.push({ path: "/news" });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__item {
  background-color: #fff;
  // margin-right: 5px;
  padding: 0 16px;
}

.s-content {
  img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
}

.active::after {
  content: " ";
  width: calc(100% - 40px);
  height: 5px;
  background: #2e64ee;
  border-radius: 5px;
  position: absolute;
  bottom: 5px;
  left: 20px;
  // opacity: 0.7;
  z-index: -1;
}

// .activeBar{
//   width: 90%;
//   height: 7px;
//   background: #29d;
//   border-radius: 5px;
//   position: absolute;
//   bottom: 20px;
//   opacity: 0.7;
//   left: 5%;
// }

.f-menu {
  cursor: pointer;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
}

.firstM {
  width: 100%;
  // justify-content: center;
  overflow-x: scroll;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.fist-menu {
  cursor: pointer;
  // width: 80px;
  height: 56px;
  flex-shrink: 0;
  // background: #000;
  background: #fff;
  text-align: center;
  line-height: 56px;
  font-size: 17px;
  padding: 0 20px;
  color: #000;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
}

.f-menu-active {
  cursor: pointer;
  width: 56px;
  height: 56px;
  // background: #fff;
  flex-shrink: 0;
}

.f-img {
  width: 22px;
  height: 22px;
}

.s-menu-t {
  color: #0094efff;
  font-size: 16px;
  margin: 0 13px 5px 16px;
  min-height: 50px;
  position: relative;
  line-height: 50px;
  border-bottom: 1px solid #eff4f5ff;
  overflow: hidden;
}

/deep/.el-menu-item.is-active {
  // width: 160px;
  width: 100%;
  color: #ffffff;
  background-color: #37588a !important;
  // color: #0086d9;
  // background: #acdefd;
  // border-right: 2px solid #0086d9;
}

/deep/.el-submenu .el-menu-item {
  min-width: 150px;
  background-color: #28292b;
}

/deep/.el-submenu.is-active .el-submenu__title {
  // color: #0086d9;
  color: #37588a;
}

/deep/.el-submenu.is-active .el-submenu__title i {
  // color: #0086d9;
  // color: #37588a;
  color: #ffffff;
}

.el-container {
  height: 100%;
  overflow-y: hidden;
}

.el-header {
  // background-color: #28292b;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.1);

  .logo {
    display: flex;
    align-items: center;
    height: 100%;

    .header_logo {
      display: flex;
      align-items: center;
      width: 311px;
      // background-color: #336084;
      background: #fff;
      height: 100%;
      justify-content: center;
      cursor: pointer;
      margin-left: -30px;

      img {
        height: 40px;
      }
    }
  }

  .user {
    margin-right: 28px;

    .userInfo {
      margin-right: 40px;
      // color: #b7c5df;
      color: #000;
      font-size: 13px;
    }

    > .el-dropdown-menu {
      top: 57px !important;
    }
  }
}

.el-aside {
  background-color: #fff;

  .el-menu {
    border: none;
  }
}

/deep/.el-main {
  padding: 0;
  height: 100%;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  // 鼠标放上去变成小手
  cursor: pointer;
}

html,
body,
#app {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 1 */
  border-top-color: #fff;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -moz-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 2 */
  border-top-color: #fff;
  -webkit-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -moz-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  /* COLOR 3 */
  -moz-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -webkit-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #7171c6;
  /* Old browsers */
  z-index: 1000;
  -webkit-transform: translateX(0);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0);
  /* IE 9 */
  transform: translateX(0);
  /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);
  /* IE 9 */
  transform: translateX(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);
  /* IE 9 */
  transform: translateX(100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%);
  /* IE 9 */
  transform: translateY(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

/* JavaScript Turned Off */
.no-js #loader-wrapper {
  display: none;
}

.no-js h1 {
  color: #222222;
}

#loader-wrapper .load_title {
  font-family: "Open Sans";
  color: #fff;
  font-size: 18px;
  width: 100%;
  text-align: center;
  z-index: 9999999999999;
  position: absolute;
  top: 60%;
  opacity: 1;
  line-height: 30px;
}

#loader-wrapper .load_title span {
  font-weight: normal;
  font-style: italic;
  font-size: 13px;
  color: #fff;
  opacity: 0.5;
}

// @media (max-width: 1366px) {
//    #loader {
//     top: 45%;
//   }
// }
.news {
  margin-right: 20px;

  img {
    position: relative;
    top: 3px;
    width: 20px;
    height: 20px;
  }

  span {
    color: #fff;
    font-size: 600;
    font-size: 13px;
    margin-left: 5px;
    position: relative;
    top: -2px;
  }
}

/deep/ .el-menu-item {
  // background: #e4e7ed;
  background-color: #37588a;
}

/deep/ .f-menu-active {
  background: #29d;
}

/deep/.el-submenu__icon-arrow {
  font-size: 16px;
  color: #ffffff;
}

// 菜单
.second-active {
  background-color: #fff;
}

.win {
  width: 100%;
  height: 100%;
  left: 200px;
  position: fixed;
  background: #22222259;
  z-index: 2005;
  color: #606266;
}

.floatMenu::-webkit-scrollbar {
  width: 0;
}

.floatMenu {
  box-sizing: border-box;
  position: fixed;
  // width: 500px;
  width: 430px;
  // height: 100%;
  // min-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 14px;
  background: #fff;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border: 1px solid #ddd;

  .second {
    padding: 5px;
    width: 150px;
    // background: #f4f4f4;
    font-size: 14px;

    .second-item {
      height: 45px;
      line-height: 45px;
      padding-left: 20px;
      width: 70px;
      //文本省略
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // .second-item:hover {
    //   background-color: #42475b28;
    // }
  }

  .third {
    width: 450px;
    padding: 5px;
    // background: #f4f1f15c;
    align-content: flex-start;
    // border-bottom: 1px solid #cfcfcf;
    font-size: 14px;

    .third-item {
      min-width: 60px;
      height: 42px;
      padding: 0 20px;
      line-height: 42px;
      padding-left: 10px;
      position: relative;
      // text-align: center;
    }

    .third-item::after {
      content: "|";
      position: absolute;
      right: 0;
      color: #ccc;
    }

    // .thirdLine{
    //   width: 1px;
    //   height: 10px;
    //   background: #000;
    // }
    .third-item:nth-last-child(1)::after {
      content: "";
    }

    .third-item:nth-child(3n)::after {
      content: "";
    }

    .third-item:hover {
      color: #409eff;
    }
  }
}

/deep/ .el-menu-item.is-active {
  background: rgb(245, 154, 35) !important;
}

.main-center {
  background: #ececec;
}

.right-menu {
  border-bottom: 1px solid rgb(214, 214, 214);
}

.fistNavName {
  padding: 10px 23px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable {
  background: #fff;
}

/deep/ .el-tabs__header {
  margin: 3px 0 0 0 !important;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/deep/ .el-tabs__item {
  height: 24px !important;
  line-height: 24px !important;
  font-size: 12px !important;
  color: #333 !important;
  border-radius: 4px 4px 0 0 !important;
}

/deep/ .el-tabs__item.is-active {
  color: #333 !important;
}

/* 设置整个滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
}

/* 设置滚动条的拖动手柄的样式 */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

/* 设置滚动条的轨道的样式 */
::-webkit-scrollbar-track {
  background-color: #eee;
}

/* 设置滚动条的拖动手柄的宽度 */
::-webkit-scrollbar-thumb {
  width: 8px;
}

::-webkit-scrollbar {
  height: 0px;
}
</style>
